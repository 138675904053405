import { get, put, post, remove } from '@/helpers/http';

export function getModelById(id) {
  return get(`/models/${id}`);
}

export function updateModelInformation(model) {
  return put(`/models/${model.id}`, model);
}

export function createNewModel(model) {
  return post('/models/', model);
}

export function removeModel(model) {
  return remove(`/models/${model.id}`);
}

export function fetchModels(pagination = {}) {
  return get('/models', { ...pagination });
}
