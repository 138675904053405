import * as modelResource from '@/modules/model/model.resource';

export function fetchModels(pagination = {}) {
  return new Promise((resolve, reject) =>
    modelResource
      .fetchModels(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getModelById(id) {
  return new Promise((resolve, reject) =>
    modelResource
      .getModelById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewModel(model) {
  return new Promise((resolve, reject) =>
    modelResource
      .createNewModel(model)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateModelInformation(model) {
  return new Promise((resolve, reject) =>
    modelResource
      .updateModelInformation(model)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeModel(model) {
  return new Promise((resolve, reject) =>
    modelResource
      .removeModel(model)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
